//Import React Functions

//Import Components
import { Card } from "@mui/material";
import MKBox from "../../MKBox";
import MKTypography from "../../MKTypography";
import MKInput from "../../MKInput";
import MKButton from "../../MKButton";

export default function LoginCard({ title, fields, button, alert }) {
  return (
    <Card>
      <MKBox
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
        mx={2}
        mt={-3}
        p={2}
        mb={1}
        textAlign="center"
      >
        <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
          {title}
        </MKTypography>
      </MKBox>
      <MKBox pt={4} pb={3} px={3}>
        <MKBox component="form" role="form">
          {fields.map((field) => (
            <MKBox mb={2}>
              <MKInput
                type="text"
                key={field.name}
                label={field.name}
                fullWidth
                value={field.value}
                onChange={field.action}
              />
            </MKBox>
          ))}

          <MKBox mt={4} mb={1}>
            {alert.map((alertMessage, index) => (
              <MKTypography
                key={index}
                sx={{
                  width: "100%",
                  background: "linear-gradient(to right, #FFB74D, #FF5722)",
                  WebkitBackgroundClip: "text",
                  color: "transparent",
                }}
              >
                {alertMessage || ""}
              </MKTypography>
            ))}
          </MKBox>

          <MKBox mt={4} mb={1}>
            <MKButton
              variant="gradient"
              color="info"
              fullWidth
              onClick={button.function}
              disabled={button.disabled}
            >
              {button.text}
            </MKButton>
          </MKBox>
        </MKBox>
      </MKBox>
    </Card>
  );
}
