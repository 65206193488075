import React, { useState } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import { styled } from "@mui/system";

// Definir los estilos usando `styled` directamente sobre el componente Card
const StyledCard = styled(Card)(({ theme, locked }) => ({
  transition: "all 0.8s cubic-bezier(0.34, 1.45, 0.7, 1)",
  transformStyle: "preserve-3d",
  backgroundColor: "transparent",
  boxShadow: "none",
  position: "relative",
  minHeight: "375px",
  ...(locked && {
    filter: "grayscale(100%)",
    cursor: "not-allowed",
  }),
}));

function RotatingCard({ children, locked }) {
  const [rotate, setRotate] = useState(false);

  const rotate0 = () => !locked && setRotate(false);
  const rotate180 = () => !locked && setRotate(true);

  return (
    <div
      style={{ perspective: "50rem" }}
      onMouseEnter={rotate180}
      onMouseLeave={rotate0}
    >
      <StyledCard
        sx={{
          transform: rotate ? "rotateY(180deg)" : "rotateY(0)",
        }}
        locked={locked ? 1 : 0} // Pasar 'locked' solo como 0 o 1 (opcional)
      >
        {children}
      </StyledCard>
    </div>
  );
}

RotatingCard.propTypes = {
  children: PropTypes.node.isRequired,
  locked: PropTypes.bool,
};

RotatingCard.defaultProps = {
  locked: false,
};

export default RotatingCard;
