//Detalles
import "./App.css";

//Theme
import theme from "./assets/theme";
import { ThemeProvider } from "@emotion/react";
import CssBaseline from "@mui/material/CssBaseline";

//Router
import Routes from "./routes";

//Redux
import { Provider } from "react-redux";
import store from "./store/store";

//Amplify
import { Amplify } from "aws-amplify";
import config from "./amplifyconfiguration.json";
import { sessionStorage } from "aws-amplify/utils";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";
Amplify.configure(config);
cognitoUserPoolsTokenProvider.setKeyValueStorage(sessionStorage);

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline>
          <Routes />
        </CssBaseline>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
