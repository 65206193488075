//import Page from "../page";
import Home from "../pages/Home";
//import Login from "../pages/Login";

import Platform from "../pages/Test_Platform";
import SignInBasic from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import ConfirmSignUp from "../pages/CorfirmerUser";
import { createBrowserRouter } from "react-router-dom";
import RecoverPassword from "../pages/RecoverPass";
import UserControl from "../pages/UserControl";

// ==============================|| MAIN ROUTING ||============================== //

const rooter = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "UserControl",
    element: <UserControl />,
  },
  {
    path: "signin",
    element: <SignInBasic />,
  },
  {
    path: "signup",
    element: <SignUp />,
  },
  {
    path: "UserHome",
    element: <Platform />,
  },
  {
    path: "recover",
    element: <RecoverPassword />,
  },
  {
    path: "confirmsignup/:email",
    element: <ConfirmSignUp />,
  },
  {
    path: "*",
    element: <Home />,
  },
]);

export default rooter;
