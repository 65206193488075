// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 React components
import Typography from "@mui/material/Typography";

// Images
//import logoCT from "assets/images/logo-ct-dark.png";
import logoNux from "../../assets/images/nuxway-isotipo.png";

const date = new Date().getFullYear();

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  brand: {
    name: "Nuxway Technology",
    image: logoNux,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/nuxway",
    },
    {
      icon: <TwitterIcon />,
      link: "https://twitter.com/Nuxway",
    },
    {
      icon: <LinkedInIcon />,
      link: "https://www.linkedin.com/company/nuxway-technology/",
    },
    {
      icon: <YouTubeIcon />,
      link: "https://www.youtube.com/channel/UCy867DMqLULPD9f2FszBzhw",
    },
  ],
  menus: [
    {
      name: "Company",
      items: [
        { name: "About Us", href: "https://www.nuxway.net/" },
        {
          name: "Products and Services",
          href: "https://www.nuxway.net/copia-de-inicio",
        },
        {
          name: "Solutions",
          href: "https://www.nuxway.net/copia-de-soluciones",
        },
        { name: "Blog", href: "https://www.nuxway.net/servicios" },
      ],
    },
  ],
  copyright: (
    <Typography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date}.
    </Typography>
  ),
};
