import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../components/MKBox";
import MKTypography from "../../components/MKTypography";
import MKInput from "../../components/MKInput";
import MKButton from "../../components/MKButton";

// Material Kit 2 React example components
import DefaultNavbar from "../../components/DefaultNavbar";
import SimpleFooter from "../../components/Footer/Simple";

// Images
import bgImage from "../../assets/images/bg-sign-in-basic.jpeg";

//Navegate
import { useNavigate, useParams } from "react-router-dom";

//AWS COGNITO
import { confirmSignUp } from "aws-amplify/auth";

function ConfirmSignUp() {
  const { email } = useParams();
  const [codeUser, setCodeUser] = useState("");

  const [codeErr, setCodeErr] = useState("");

  const navigate = useNavigate();

  const handleChangeCodeUser = (event) => {
    setCodeUser(event.target.value);
  };

  async function handleConfirnSignUp() {
    console.log("Intentado...: ", email, codeUser);
    try {
      await confirmSignUp({
        username: email,
        confirmationCode: codeUser,
      });

      navigate("/signin");
    } catch (error) {
      console.log("error confirming:", error.message);
      setCodeErr(error.message);
    }
  }

  const routes = [{}, {}];

  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "internal",
          route: "/signin",
          label: "Sign In",
          color: "info",
        }}
        transparent
        light
      />
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MKBox
        px={1}
        width="100%"
        height="100vh"
        mx="auto"
        position="relative"
        zIndex={2}
      >
        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <MKBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MKTypography
                  variant="h4"
                  fontWeight="medium"
                  color="white"
                  mt={1}
                >
                  Verify User
                </MKTypography>
              </MKBox>
              <MKBox pt={4} pb={3} px={3}>
                <MKBox component="form" role="form">
                  <MKBox mb={2}>
                    <MKInput
                      type="text"
                      label="Code"
                      fullWidth
                      value={codeUser}
                      onChange={handleChangeCodeUser}
                    />
                  </MKBox>
                  <MKBox mt={4} mb={1}>
                    <MKTypography variant="body2" fontWeight="medium" mt={1}>
                      Insert the code sent to your email.
                    </MKTypography>
                    <MKTypography variant="gradient" color="warning" fullWidth>
                      {codeErr}
                    </MKTypography>
                  </MKBox>
                  <MKBox mt={4} mb={1}>
                    <MKButton
                      variant="gradient"
                      color="info"
                      fullWidth
                      onClick={handleConfirnSignUp}
                    >
                      Confirm
                    </MKButton>
                  </MKBox>
                </MKBox>
              </MKBox>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
      <MKBox width="100%" position="absolute" zIndex={2} bottom="1.625rem">
        <SimpleFooter light />
      </MKBox>
    </>
  );
}

export default ConfirmSignUp;
