import { createSlice } from "@reduxjs/toolkit";

export const cognitoUserSlice = createSlice({
  name: "cognitoUser",
  initialState: {
    value: { email: null, pass: null, values: {} },
  },
  reducers: {
    setCognitoUser: (state, action) => {
      state.value.values = action.payload;
    },
    setEmailUser: (state, action) => {
      state.value.email = action.payload;
    },
    setPassUser: (state, action) => {
      state.value.pass = action.payload;
    },
    cognitoSignOut: (state) => {
      state.value = { email: null, pass: null, values: {} };
    },
  },
});

export const { setCognitoUser, setEmailUser, setPassUser, cognitoSignOut } =
  cognitoUserSlice.actions;

export default cognitoUserSlice.reducer;
