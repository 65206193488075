import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../components/MKBox";
import MKTypography from "../../components/MKTypography";
import MKInput from "../../components/MKInput";
import MKButton from "../../components/MKButton";

//AWS COGNITO
import { signUp } from "aws-amplify/auth";
import { useDispatch } from "react-redux";
import {
  setCognitoUser,
  setEmailUser,
  setPassUser,
} from "../../store/features/cognitoUserSlice";

function SignUpUser({ setStateRender }) {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("70000000");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [loginErr, setLoginErr] = useState("");
  const [checkPassErr, setCheckPassErr] = useState("");

  const [ableSignUp, setAbleSignUp] = useState(true);

  const dispatch = useDispatch();

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleChangeName = (event) => {
    setName(event.target.value);
  };

  const handleChangePhoneNumber = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleChangePassword = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);

    const hasUpperCase = /[A-Z]/.test(newPassword);
    const hasLowerCase = /[a-z]/.test(newPassword);
    const hasNumber = /[0-9]/.test(newPassword);
    const hasSpecialChar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(
      newPassword
    );
    const hasMinLength = newPassword.length >= 8;

    if (!hasUpperCase) {
      setCheckPassErr("Password must contain al least 1 uppercase letter.");
    } else if (!hasLowerCase) {
      setCheckPassErr("Password must contain al least 1 lowercase letter.");
    } else if (!hasNumber) {
      setCheckPassErr("Password must contain al least 1 number.");
    } else if (!hasSpecialChar) {
      setCheckPassErr("Password must contain al least 1 special character.");
    } else if (!hasMinLength) {
      setCheckPassErr("Password must contain al least 8 characters.");
    } else {
      setCheckPassErr("");
    }

    if (checkPassErr !== "") {
      setAbleSignUp(false);
    }
  };

  const handleChangeConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
  };

  useEffect(() => {
    if (confirmPassword === password) {
      setAbleSignUp(true);
      setCheckPassErr("");
    } else {
      if (confirmPassword !== password) {
        setCheckPassErr("Passwords must match!");
        setAbleSignUp(false);
        console.log("Esta vaina no es igual!");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmPassword]);

  async function handleSignUp() {
    try {
      const { isSignUpComplete, userId, nextStep } = await signUp({
        username: email,
        password: password,
        options: {
          userAttributes: {
            "custom:cell_phone": phoneNumber,
            "custom:user_name": name,
          },
        },
      });
      console.log(isSignUpComplete, userId, nextStep);
      if (nextStep.signUpStep === "CONFIRM_SIGN_UP") {
        dispatch(setEmailUser(email));
        dispatch(setPassUser(password));
        dispatch(setCognitoUser(userId));
        setStateRender("ConfirmSignUp");
      }
    } catch (error) {
      console.log("error signing up:", error.message);
      setLoginErr(error.message);
    }
  }

  return (
    <>
      <Grid
        container
        spacing={1}
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
          <Card>
            <MKBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              p={2}
              mb={1}
              textAlign="center"
            >
              <MKTypography
                variant="h4"
                fontWeight="medium"
                color="white"
                mt={1}
              >
                Create User
              </MKTypography>
            </MKBox>
            <MKBox pt={4} pb={3} px={3}>
              <MKBox component="form" role="form">
                <MKBox mb={2}>
                  <MKInput
                    type="email"
                    label="Email"
                    fullWidth
                    value={email}
                    onChange={handleChangeEmail}
                  />
                </MKBox>
                <MKBox mb={2}>
                  <MKInput
                    type="text"
                    label="Name"
                    fullWidth
                    value={name}
                    onChange={handleChangeName}
                  />
                </MKBox>
                <MKBox mb={2}>
                  <MKInput
                    type="password"
                    label="Password"
                    fullWidth
                    value={password}
                    onChange={handleChangePassword}
                  />
                </MKBox>
                <MKBox mb={2}>
                  <MKInput
                    type="password"
                    label="Confirm Password"
                    fullWidth
                    value={confirmPassword}
                    onChange={handleChangeConfirmPassword}
                  />
                </MKBox>
                <MKBox mt={4} mb={1}>
                  <MKTypography
                    variant="gradient"
                    color="warning"
                    sx={{ width: "100%" }}
                  >
                    {loginErr}
                  </MKTypography>
                  <MKTypography
                    variant="h6"
                    color="warning"
                    sx={{ width: "100%" }}
                  >
                    {checkPassErr}
                  </MKTypography>
                </MKBox>
                <MKBox mt={4} mb={1}>
                  <MKButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    onClick={handleSignUp}
                    disabled={!ableSignUp}
                  >
                    Sign up
                  </MKButton>
                </MKBox>
              </MKBox>
            </MKBox>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default SignUpUser;
