import React, { Suspense, lazy } from "react";
import Footer from "../../components/Footer/Default";
import footerRoutes from "../../components/Footer/footer.routes";
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import MKTypography from "../../components/MKTypography";
import MKBox from "../../components/MKBox";
import bgImage from "../../assets/images/bg_campaigns.jpg";
import NuxLogo from "../../assets/images/NuxTech-cut (1).webp";
import InnovaLogo from "../../assets/images/NuxTech-cut (2).avif";
import CenteredBlogCard from "../../components/Card/CenteredBlogCard";
import MKButton from "../../components/MKButton";
import Header from "../../components/Header";
import NuxCallerBanner from "../../assets/images/BannerNuxCaller.jpg";

// Carga del LiveChatBubble de forma lazy
const LiveChatBubble = lazy(() => import("../../components/LiveChat"));

export default function HomePage() {
  const sections = {
    nuxway: {
      title: "Visit Our Web Page",
      description:
        "Visit us to discover all our services and products to give you a communication system and upgrade your enterprise!",
      image: NuxLogo,
      button: "Discover More!",
      link: "https://nuxway.net",
    },
    nuxservices: {
      title: "Access Our Services",
      description:
        "From Automatic Dialer to Call Center Monitoring, explore everything our cloud services have to offer you!",
      image: InnovaLogo,
      button: "Sign In",
      link: "/UserControl",
    },
  };

  return (
    <Box sx={{ bgcolor: "background.default" }}>
      <MKBox position="fixed" top="0.5rem" width="100%" zIndex={10}>
        <Header />
      </MKBox>
      <Box width={"100%"}>
        <MKBox
          minHeight="75vh"
          width="100%"
          sx={{
            backgroundImage: ({
              functions: { linearGradient, rgba },
              palette: { gradients },
            }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.6),
                rgba(gradients.dark.state, 0.6)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Box
            width={{ xs: "90%", sm: "80%", md: "70%", lg: "60%" }}
            sx={{
              mt: { xs: 2, sm: 3, md: 5 },
              mx: "auto",
              textAlign: "center",
            }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                fontFamily: "'Poppins', sans-serif", // Cambiar la fuente a Poppins
                fontWeight: "bold",
                fontSize: {
                  xs: size["xl"],
                  sm: size["2xl"],
                  md: size["3xl"],
                  lg: size["4xl"],
                },
                textAlign: "center",
              })}
            >
              Innovation and Web Development Area
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              opacity={0.8}
              mt={{ xs: 1, md: 2 }}
              mb={{ xs: 2, md: 3 }}
              sx={{
                fontFamily: "'Poppins', sans-serif", // Cambiar la fuente a Poppins
                textAlign: "center",
              }}
            >
              We develop serverless applications that integrate with unified
              communications (UC) servers through APIs, applying new ideas and
              innovative methods to optimize companies' communication processes.
            </MKTypography>
          </Box>
        </MKBox>
        {/* Cards Section*/}
        <Box width={"100%"} sx={{ justifyContent: "center", display: "flex" }}>
          <Box
            sx={{
              mt: "-100px",
              bgcolor: "background.card",
              display: "flex",
              justifyContent: "center",
              borderRadius: 10,
              mb: 5,
            }}
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              display={"flex"}
              pt={5}
              spacing={10}
            >
              <Grid item xs={10} lg={4} sx={{ mt: { xs: 3, lg: 0 } }}>
                <CenteredBlogCard
                  image={sections.nuxway.image}
                  title={sections.nuxway.title}
                  description={sections.nuxway.description}
                  action={{
                    type: "external",
                    route: sections.nuxway.link,
                    color: "info",
                    label: sections.nuxway.button,
                  }}
                />
              </Grid>
              <Grid item xs={10} lg={4} sx={{ mt: { xs: 3, lg: 0 } }}>
                <CenteredBlogCard
                  image={sections.nuxservices.image}
                  title={sections.nuxservices.title}
                  description={sections.nuxservices.description}
                  action={{
                    type: "internal",
                    route: sections.nuxservices.link,
                    color: "info",
                    label: sections.nuxservices.button,
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>

        {/* Free PBX Section */}
        <Box
          sx={{
            background: "linear-gradient(to right, #125b9c, #002147)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            p: 2,
            mb: 5,
          }}
        >
          <Box
            width={"60%"}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
          >
            <MKTypography
              variant="h5"
              color="white"
              mt={1}
              mb={3}
              sx={{
                fontFamily: "'Poppins', sans-serif",
                fontSize:"25px",
                textAlign: "center",
                width: "100%",
              }}
            >
              Get a free trial of a Yeastar Cloud PBX
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              mt={1}
              mb={3}
              sx={{
                fontFamily: "'Poppins', sans-serif", // Cambiar la fuente a Poppins
                textAlign: "center",
                width: "100%",
              }}
            >
              Click on button and start your FREE TEST!
            </MKTypography>
            <MKButton
              href={
                "https://www.yeastar.com/p-series-free-trial/?referralId=603874803526407561"
              }
              target="_blank"
              rel="noreferrer"
              color="white"
              size="small"
              sx={{ m: 2, width: "fit-content" }}
            >
              {"Test it!"}
            </MKButton>
          </Box>
        </Box>

        {/* Youtube Section */}
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="row"
          bgcolor="#ac362e"
          p={2}
          mb={5}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={12} md={6}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  srcSet={`${NuxCallerBanner}?w=120&fit=crop&auto=format&dpr=2 2x`}
                  src={`${NuxCallerBanner}?w=120&fit=crop&auto=format`}
                  alt="NuxTako"
                  loading="lazy"
                  style={{ width: "100%", height: "auto" }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                width="100%"
                display="flex"
                flexDirection="column"
                alignItems="center"
                height="100%"
                sx={{ backgroundColor: "#363636" }}
              >
                <Box sx={{ p: 2.5, width: "100%", height: "350px" }}>
                  <iframe
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/videoseries?si=q42HQpCX8fkExF8B&list=PLT2m3I1lDRJ7EwHzXHy-tqEXkXpbsMd30"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                  ></iframe>
                </Box>
                <MKButton
                  href={"https://youtube.com/@nuxway?si=emVLcZbykOr1g6FE"}
                  target="_blank"
                  rel="noreferrer"
                  color="info"
                  sx={{
                    m: 2,
                    width: "fit-content",
                  }}
                  size="small"
                >
                  {"Check our youtube channel!"}
                </MKButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {/* Footer Section */}
        <Footer content={footerRoutes} />
        <Suspense fallback={<div>Loading...</div>}>
          <LiveChatBubble />
        </Suspense>
      </Box>
    </Box>
  );
}
