import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

//AWS COGNITO
import { resetPassword, confirmResetPassword } from "aws-amplify/auth";
import LoginCard from "../../components/Card/LoginCard";

function RecoverPassword({ setStateRender }) {
  const [email, setEmail] = useState("");
  const [codeEmail, setCodeEmail] = useState("");
  const [newPass, setNewPass] = useState("");
  const [checkNewPass, setCheckNewPass] = useState("");

  const [errEmail, setErrEmail] = useState("");
  const [errPass, setErrPass] = useState("");

  const [flagShow, setFlagShow] = useState(true);
  const [ableResetPass, setAbleResetPass] = useState(false);

  const sendCodeEmail = async () => {
    try {
      const { nextStep } = await resetPassword({
        username: email,
      });
      switch (nextStep.resetPasswordStep) {
        case "CONFIRM_RESET_PASSWORD_WITH_CODE":
          const codeDeliveryDetails = nextStep.codeDeliveryDetails;
          console.log(
            `Confirmation code was sent to ${codeDeliveryDetails.deliveryMedium}`
          );
          // Collect the confirmation code from the user and pass to confirmResetPassword.
          break;
        case "DONE":
          console.log("Successfully reset password.");
          break;
        default:
          console.log("Something happen...");
          setErrEmail("There was a mistake...");
          break;
      }
      setFlagShow(false);
    } catch (error) {
      setErrEmail("Email error:", error);
    }
  };

  const setNewPassword = async () => {
    try {
      console.log("Sending data:", email, codeEmail, newPass);
      await confirmResetPassword({
        username: email,
        confirmationCode: codeEmail,
        newPassword: newPass,
      });
      setStateRender("signIn");
    } catch (error) {
      console.log(error);
      setErrPass("Reseating Password error:", JSON.stringify(error, null, 2));
    }
  };

  const handleCheckPasswords = (event) => {
    const value = event.target.value;
    setCheckNewPass(value);
    if (newPass !== value) {
      setErrPass("Passwords do not match!");
    } else {
      setErrPass("");
    }
  };

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleChangeNewPass = (event) => {
    const newPassword = event.target.value;
    setNewPass(newPassword);

    const hasUpperCase = /[A-Z]/.test(newPassword);
    const hasLowerCase = /[a-z]/.test(newPassword);
    const hasNumber = /[0-9]/.test(newPassword);
    const hasSpecialChar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(
      newPassword
    );
    const hasMinLength = newPassword.length >= 8;

    if (!hasUpperCase) {
      setErrPass("Password must contain al least 1 uppercase letter.");
    } else if (!hasLowerCase) {
      setErrPass("Password must contain al least 1 lowercase letter.");
    } else if (!hasNumber) {
      setErrPass("Password must contain al least 1 number.");
    } else if (!hasSpecialChar) {
      setErrPass("Password must contain al least 1 special character.");
    } else if (!hasMinLength) {
      setErrPass("Password must contain al least 8 characters.");
    } else {
      setErrPass("");
    }

    if (errPass !== "") {
      setAbleResetPass(true);
    }
  };

  const handleChangeCode = (event) => {
    setCodeEmail(event.target.value);
  };

  return (
    <>
      <Grid
        container
        spacing={1}
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
          {flagShow ? (
            <LoginCard
              title="Recover Password"
              fields={[
                { name: "Email", value: email, action: handleChangeEmail },
              ]}
              button={{ function: sendCodeEmail, text: "Send" }}
              alert={[errEmail]}
            />
          ) : (
            <LoginCard
              title="Recover Password"
              fields={[
                {
                  name: "Code",
                  value: codeEmail,
                  action: handleChangeCode,
                },
                {
                  name: "New Password",
                  value: newPass,
                  action: handleChangeNewPass,
                },
                {
                  name: "Confirm Password",
                  value: checkNewPass,
                  action: handleCheckPasswords,
                },
              ]}
              button={{
                function: setNewPassword,
                text: "Reset Password",
                disabled: !ableResetPass,
              }}
              alert={[errPass]}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default RecoverPassword;
