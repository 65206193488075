
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  defaultProps: {
    color: "transparent",
  },

  styleOverrides: {
    root: {
      boxShadow: "none",
    },
  },
};
