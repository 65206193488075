
// Material Kit 2 React Base Styles
import colors from "../../base/colors";

const { transparent } = colors;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  styleOverrides: {
    root: {
      backgroundColor: transparent.main,
    },
  },
};
