import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

//AWS COGNITO
import { confirmSignIn } from "aws-amplify/auth";
import LoginCard from "../../components/Card/LoginCard";

function ResetPassword({ setStateRender }) {
  const [newPass, setNewPass] = useState("");
  const [checkNewPass, setCheckNewPass] = useState("");
  const [errPass, setErrPass] = useState("");

  const [buttonDisabled, setButtonDisabled] = useState(true);

  const setNewPassword = async () => {
    try {
      console.log("Sending Data:", newPass);
      await confirmSignIn({
        newPassword: newPass,
      });
      setStateRender("signIn");
    } catch (error) {
      console.log(error);
      setErrPass(error);
    }
  };

  const handleCheckPasswords = (event) => {
    const value = event.target.value;
    setCheckNewPass(value);
    if (newPass !== value) {
      setErrPass("Passwords do not match!");
    } else {
      setErrPass("");
      setButtonDisabled(false);
    }
  };

  const handleChangeNewPass = (event) => {
    const newPassword = event.target.value;
    setNewPass(newPassword);

    const hasUpperCase = /[A-Z]/.test(newPassword);
    const hasLowerCase = /[a-z]/.test(newPassword);
    const hasNumber = /[0-9]/.test(newPassword);
    const hasSpecialChar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(
      newPassword
    );
    const hasMinLength = newPassword.length >= 8;

    if (!hasUpperCase) {
      setErrPass("Password must contain al least 1 uppercase letter.");
    } else if (!hasLowerCase) {
      setErrPass("Password must contain al least 1 lowercase letter.");
    } else if (!hasNumber) {
      setErrPass("Password must contain al least 1 number.");
    } else if (!hasSpecialChar) {
      setErrPass("Password must contain al least 1 special character.");
    } else if (!hasMinLength) {
      setErrPass("Password must contain al least 8 characters.");
    } else {
      setErrPass("");
    }

    if (errPass !== "") {
      setButtonDisabled(false);
    }
  };

  return (
    <>
      <Grid
        container
        spacing={1}
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
          <LoginCard
            title="Reset Password"
            fields={[
              {
                name: "New Password",
                value: newPass,
                action: handleChangeNewPass,
              },
              {
                name: "Confirm Password",
                value: checkNewPass,
                action: handleCheckPasswords,
              },
            ]}
            button={{
              function: setNewPassword,
              text: "Reset Password",
              disabled: buttonDisabled,
            }}
            alert={[errPass]}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default ResetPassword;
