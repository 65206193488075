// @mui/material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../../components/MKBox";

// Material Kit 2 React examples
import RotatingCard from "../../../components/Card/RotatingCard";
import RotatingCardFront from "../../../components/Card/RotatingCard/RotatingCardFront";
import RotatingCardBack from "../../../components/Card/RotatingCard/RotatingCardBack";
// import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "../../../assets/images/rotating-card-bg-front.jpeg";
import bgBack from "../../../assets/images/rotating-card-bg-back.jpeg";

// Mui Icons
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import CryptoJS from "crypto-js";

export function encryptString(text, secretKey) {
  // Encriptar el texto
  const encrypted = CryptoJS.AES.encrypt(text, secretKey).toString();

  // Convertir el texto encriptado a Base64 URL-safe
  const base64Url = encrypted
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, ""); // Eliminar caracteres de relleno (=)

  return base64Url;
}

function Information() {
  const cognitoUser = useSelector((state) => state.cognitoUser.value);

  const [creds, setCreds] = useState("");

  useEffect(() => {
    if (cognitoUser?.email && cognitoUser?.pass) {
      const userData = JSON.stringify({
        email: cognitoUser.email,
        pass: cognitoUser.pass,
      });

      console.log("Intentando encriptar:", userData);
      setCreds(encryptString(userData, "nuxwayservices"));
      console.log("Encriptado:", creds);
    }
  }, [cognitoUser]);

  useEffect(() => {
    const printMessage = () => {
      console.log("Han pasado 5 minutos");
    };
    const timer = setInterval(() => {
      printMessage();
    }, 300000);

    return () => clearInterval(timer);
  }, []);

  return (
    <MKBox component="section" py={6} my={3}>
      <Container>
        <Grid
          container
          item
          xs={11}
          spacing={3}
          alignItems="center"
          sx={{ mx: "auto" }}
        >
          {/* Primer Item */}
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard locked={false}>
              <RotatingCardFront
                image={bgFront}
                icon={<ContactPhoneIcon />}
                title={<>NuxCaller</>}
                description="Automated interactive voice call service for reminders or advertising."
              />
              <RotatingCardBack
                image={bgBack}
                title="Make automatic Calls!"
                description="Select an IVR with which you will call all your clients!"
                action={{
                  type: "external",
                  route: `https://caller.nuxway.services?user=${creds}`,
                  label: "Access",
                }}
              />
            </RotatingCard>
          </Grid>
          {/* Segundo Item */}
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard locked>
              <RotatingCardFront
                image={bgFront}
                icon={<ForwardToInboxIcon />}
                title={<>NuxSMS</>}
                description="Software for automatic send of SMS in bulk."
                locked
              />
              <RotatingCardBack
                image={bgBack}
                title="Send Messages"
                description="Make a Custom Message for all you clients!"
                action={{
                  type: "internal",
                  route: "/",
                  label: "Access",
                }}
                locked
              />
            </RotatingCard>
          </Grid>
          {/* Tercer item */}
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard locked>
              <RotatingCardFront
                image={bgFront}
                icon={<DashboardIcon />}
                title={<>NuxCenter</>}
                description="Report generation and real-time monitoring service for Call Centers!"
                locked
              />
              <RotatingCardBack
                image={bgBack}
                title="Supervise and Monitor"
                description="Know the status of your Call Center with historical reports and real-time monitoring."
                action={{
                  type: "internal",
                  route: "/",
                  label: "Access",
                }}
                locked
              />
            </RotatingCard>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
