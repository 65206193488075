import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../components/MKBox";
import MKTypography from "../../components/MKTypography";
import MKInput from "../../components/MKInput";
import MKButton from "../../components/MKButton";

//AWS COGNITO
import { confirmSignUp } from "aws-amplify/auth";
import { useSelector } from "react-redux";

function CodeUser({ setStateRender }) {
  const [codeUser, setCodeUser] = useState("");
  const [codeErr, setCodeErr] = useState("");

  const cognitoUser = useSelector((state) => state.cognitoUser.value);

  const handleChangeCodeUser = (event) => {
    setCodeUser(event.target.value);
  };

  async function handleConfirmSignUp() {
    try {
      await confirmSignUp({
        username: cognitoUser.email,
        confirmationCode: codeUser,
      });
      setStateRender("signIn");
    } catch (error) {
      console.log("error confirming:", error.message);
      setCodeErr(error.message);
    }
  }

  return (
    <>
      <Grid
        container
        spacing={1}
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
          <Card>
            <MKBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              p={2}
              mb={1}
              textAlign="center"
            >
              <MKTypography
                variant="h4"
                fontWeight="medium"
                color="white"
                mt={1}
              >
                Verify User
              </MKTypography>
            </MKBox>
            <MKBox pt={4} pb={3} px={3}>
              <MKBox component="form" role="form">
                <MKBox mb={2}>
                  <MKInput
                    type="text"
                    label="Code"
                    fullWidth
                    value={codeUser}
                    onChange={handleChangeCodeUser}
                  />
                </MKBox>
                <MKBox mt={4} mb={1}>
                  <MKTypography variant="body2" fontWeight="medium" mt={1}>
                    Insert the code sent to your email.
                  </MKTypography>
                  <MKTypography
                    variant="gradient"
                    color="warning"
                    sx={{ width: "100%" }}
                  >
                    {codeErr}
                  </MKTypography>
                </MKBox>
                <MKBox mt={4} mb={1}>
                  <MKButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    onClick={handleConfirmSignUp}
                  >
                    Confirm
                  </MKButton>
                </MKBox>
              </MKBox>
            </MKBox>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default CodeUser;
