import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../components/MKBox";
import MKTypography from "../../components/MKTypography";
import MKInput from "../../components/MKInput";
import MKButton from "../../components/MKButton";

// Material Kit 2 React example components
import DefaultNavbar from "../../components/DefaultNavbar";
import SimpleFooter from "../../components/Footer/Simple";

// Images
import bgImage from "../../assets/images/bg-sign-in-basic.jpeg";

//Navegate
import { useNavigate } from "react-router-dom";

import { setTokenTrue } from "../../store/features/tokenSlice";
import { useDispatch } from "react-redux";

//AWS COGNITO
import { signIn } from "aws-amplify/auth";

function SignInBasic() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginErr, setLoginErr] = useState("");

  //Confirmed User
  const [confirmedUser, setConfirmedUser] = useState(true);
  const [cognitoUserName, setCognitoUserName] = useState("");

  const [requiredNewPass, setRequiredNewPass] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      localStorage.getItem("email") !== null &&
      localStorage.getItem("pass") !== null
    ) {
      console.log(
        "Credenciales encontradas!: ",
        localStorage.getItem("email"),
        " ",
        localStorage.getItem("pass")
      );
      dispatch(setTokenTrue());
      navigate("/UserHome");
    }
  });

  useEffect(() => {
    if (requiredNewPass === true) {
      navigate("/requirednewpass");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requiredNewPass]);

  const handleForgetPass = () => {
    console.log("Oh nyo!");
    navigate("/recover");
  };

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleAlreadyUser = () => {
    localStorage.setItem("email", email);
    const encodedPassword = encodeURIComponent(password);
    localStorage.setItem("pass", encodedPassword);
    dispatch(setTokenTrue());
  };

  const handleSignIn = async () => {
    try {
      var { isSignedIn, nextStep } = await signIn({
        username: email,
        password: password,
      });
      if (isSignedIn) {
        handleAlreadyUser();
        navigate("/UserHome");
      }
      console.log("User:", isSignedIn, nextStep);
      switch (nextStep.signInStep) {
        case "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED":
          navigate("/resetpassword");
          break;
        case "RESET_PASSWORD":
          navigate("/resetpassword");
          break;
        case "CONFIRM_SIGN_UP":
          navigate(`/confirmsignup/${email}`);
          break;
        case "DONE":
          handleAlreadyUser();
          navigate("/UserHome");
          break;

        default:
          setLoginErr(
            "Something wrong happen, please contact Nuxway Technology",
            nextStep
          );
          break;
      }
    } catch (err) {
      console.log("error de inicio de sesion:", err);
      setLoginErr(err.message);
      if (err.message === "User is not confirmed.") {
        setCognitoUserName(email);
        setConfirmedUser(false);
      } else if (err.message === "") {
        setRequiredNewPass(true);
      }
    }
    console.log(cognitoUserName, confirmedUser);
  };

  const routes = [{}, {}];

  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "internal",
          route: "/signup",
          label: "Sign Up",
          color: "info",
        }}
        transparent
        light
      />
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MKBox
        px={1}
        width="100%"
        height="100vh"
        mx="auto"
        position="relative"
        zIndex={2}
      >
        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <MKBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MKTypography
                  variant="h4"
                  fontWeight="medium"
                  color="white"
                  mt={1}
                >
                  Nuxway
                </MKTypography>
              </MKBox>
              <MKBox pt={4} pb={3} px={3}>
                <MKBox component="form" role="form">
                  <MKBox mb={2}>
                    <MKInput
                      type="email"
                      label="Email"
                      fullWidth
                      value={email}
                      onChange={handleChangeEmail}
                    />
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput
                      type="password"
                      label="Password"
                      fullWidth
                      value={password}
                      onChange={handleChangePassword}
                    />
                    <MKTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      onClick={handleForgetPass}
                      sx={{
                        cursor: "pointer",
                        userSelect: "none",
                        ml: -1,
                        "&:hover": {
                          color: "warning.main",
                        },
                      }}
                    >
                      &nbsp;&nbsp;Forgot password?
                    </MKTypography>
                  </MKBox>
                  <MKBox mt={4} mb={1}>
                    <MKTypography variant="gradient" color="warning" fullWidth>
                      {loginErr}
                    </MKTypography>
                  </MKBox>
                  <MKBox mt={4} mb={1}>
                    <MKButton
                      variant="gradient"
                      color="info"
                      fullWidth
                      onClick={handleSignIn}
                    >
                      Sign In
                    </MKButton>
                  </MKBox>
                </MKBox>
              </MKBox>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
      <MKBox width="100%" position="absolute" zIndex={2} bottom="1.625rem">
        <SimpleFooter light />
      </MKBox>
    </>
  );
}

export default SignInBasic;
