import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../components/MKBox";
import MKTypography from "../../components/MKTypography";
import MKInput from "../../components/MKInput";
import MKButton from "../../components/MKButton";

//Redux
import { useDispatch } from "react-redux";

//AWS COGNITO
import { signIn } from "aws-amplify/auth";
import {
  setCognitoUser,
  setEmailUser,
  setPassUser,
} from "../../store/features/cognitoUserSlice";

function SignInUser({ setStateRender }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginErr, setLoginErr] = useState("");

  const dispatch = useDispatch();

  const handleForgetPass = () => {
    console.log("Oh nyo!");
    setStateRender("ForgotPassword");
  };

  //Handle Changes
  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };

  //SingIn
  const handleSignIn = async () => {
    try {
      var { isSignedIn, nextStep } = await signIn({
        username: email,
        password: password,
      });

      //Saving Data
      dispatch(setEmailUser(email));
      dispatch(setPassUser(password));
      dispatch(setCognitoUser({ isSignedIn, nextStep }));

      console.log("User:", isSignedIn, nextStep);
      switch (nextStep.signInStep) {
        case "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED":
          setStateRender("ResetPassword");
          break;
        case "RESET_PASSWORD":
          setStateRender("ResetPassword");
          break;
        case "CONFIRM_SIGN_UP":
          setStateRender(`ConfirmSignUp`);
          break;
        case "DONE":
          setStateRender("UserHome");
          break;

        default:
          setLoginErr(
            "Something wrong happen, please contact Nuxway Technology",
            nextStep
          );
          break;
      }
    } catch (err) {
      console.log("Error in SignIn:", err);
      setLoginErr("Error:", err);
    }
  };

  return (
    <>
      <Grid
        container
        spacing={1}
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
          <Card>
            <MKBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              p={2}
              mb={1}
              textAlign="center"
            >
              <MKTypography
                variant="h4"
                fontWeight="medium"
                color="white"
                mt={1}
              >
                Nuxway
              </MKTypography>
            </MKBox>
            <MKBox pt={4} pb={3} px={3}>
              <MKBox component="form" role="form">
                <MKBox mb={2}>
                  <MKInput
                    type="email"
                    label="Email"
                    fullWidth
                    value={email}
                    onChange={handleChangeEmail}
                  />
                </MKBox>
                <MKBox mb={2}>
                  <MKInput
                    type="password"
                    label="Password"
                    fullWidth
                    value={password}
                    onChange={handleChangePassword}
                  />
                  <MKTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    onClick={handleForgetPass}
                    sx={{
                      cursor: "pointer",
                      userSelect: "none",
                      ml: -1,
                      "&:hover": {
                        color: "warning.main",
                      },
                    }}
                  >
                    &nbsp;&nbsp;Forgot password?
                  </MKTypography>
                </MKBox>
                <MKBox mt={4} mb={1}>
                  <MKTypography variant="gradient" color="warning">
                    {loginErr}
                  </MKTypography>
                </MKBox>
                <MKBox mt={4} mb={1}>
                  <MKButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    onClick={handleSignIn}
                  >
                    Sign In
                  </MKButton>
                </MKBox>
              </MKBox>
            </MKBox>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default SignInUser;
