// @mui material components
//import Container from "@mui/material/Container";
//import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "../../components/MKBox";

// Material Kit 2 React examples
//import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "../../components/Footer/Default";
import footerRoutes from "../../components/Footer/footer.routes";

// Presentation page sections
import Information from "./sections/Information";

//import Header2 from "../../components/Header";
import DefaultNavbar from "../../components/DefaultNavbar";
import { getCurrentUser } from "aws-amplify/auth";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Images
//import bgImage from "assets/images/bg-presentation.jpg";

function Platform() {
  const cognitoUser = useSelector((state) => state.cognitoUser.value);
  const navigate = useNavigate();

  async function currentAuthenticatedUser() {
    try {
      const { username, userId, signInDetails } = await getCurrentUser();
      console.log(`The username: ${username}`);
      console.log(`The userId: ${userId}`);
      console.log(`The signInDetails: ${signInDetails}`);
    } catch (err) {
      console.log(err);
      navigate("/home");
    }
  }

  //Recognize User
  useEffect(() => {
    try {
      currentAuthenticatedUser();
    } catch (error) {
      console.log("Error verifying user:", error);
      navigate("/home");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cognitoUser]);
  const routes = [{}, {}];

  return (
    <MKBox sx={{ backgroundColor: "grey.300" }}>
      <MKBox position="fixed" top="0.5rem" width="100%" zIndex={10}>
        <DefaultNavbar
          routes={routes}
          action={{
            type: "internal",
            route: "/signin",
            label: "Sign In",
            color: "default",
          }}
        />
      </MKBox>
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: 12,
            mb: 4,
            backgroundColor: ({
              palette: { background },
              functions: { rgba },
            }) => rgba(background.default, 0.9),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <Information />
        </Card>
      </MKBox>
      {/* Footer */}
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </MKBox>
  );
}

export default Platform;
