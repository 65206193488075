import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../components/MKBox";
import MKTypography from "../../components/MKTypography";
import MKInput from "../../components/MKInput";
import MKButton from "../../components/MKButton";

// Material Kit 2 React example components
import DefaultNavbar from "../../components/DefaultNavbar";
import SimpleFooter from "../../components/Footer/Simple";

// Images
import bgImage from "../../assets/images/bg-sign-in-basic.jpeg";

//Navegate
import { useNavigate } from "react-router-dom";

//AWS COGNITO
import { signUp } from "aws-amplify/auth";

function SignUp() {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("70000000");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [loginErr, setLoginErr] = useState("");
  const [checkPassErr, setCheckPassErr] = useState("");

  const [ableSignUp, setAbleSignUp] = useState(true);

  const navigate = useNavigate();

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleChangeName = (event) => {
    setName(event.target.value);
  };

  const handleChangePhoneNumber = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleChangePassword = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);

    const hasUpperCase = /[A-Z]/.test(newPassword);
    const hasLowerCase = /[a-z]/.test(newPassword);
    const hasNumber = /[0-9]/.test(newPassword);
    const hasSpecialChar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(
      newPassword
    );
    const hasMinLength = newPassword.length >= 8;

    if (!hasUpperCase) {
      setCheckPassErr("Password must contain al least 1 uppercase letter.");
    } else if (!hasLowerCase) {
      setCheckPassErr("Password must contain al least 1 lowercase letter.");
    } else if (!hasNumber) {
      setCheckPassErr("Password must contain al least 1 number.");
    } else if (!hasSpecialChar) {
      setCheckPassErr("Password must contain al least 1 special character.");
    } else if (!hasMinLength) {
      setCheckPassErr("Password must contain al least 8 characters.");
    } else {
      setCheckPassErr("");
    }

    if (checkPassErr !== "") {
      setAbleSignUp(false);
    }
  };

  const handleChangeConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
  };

  useEffect(() => {
    if (confirmPassword === password) {
      setAbleSignUp(true);
      setCheckPassErr("");
    } else {
      if (confirmPassword !== password) {
        setCheckPassErr("Passwords must match!");
        setAbleSignUp(false);
        console.log("Esta vaina no es igual!");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmPassword]);

  // const handleAlreadyUser = () => {
  //   sessionStorage.setItem("email", email);
  //   const encodedPassword = encodeURIComponent(password);
  //   sessionStorage.setItem("pass", encodedPassword);
  //   dispatch(setTokenTrue());
  // };

  // const handleSignIn = async () => {
  //   try {
  //     var user = await signIn({ username: email, password: password });
  //     handleAlreadyUser();
  //     navigate("/UserHome");
  //   } catch (err) {
  //     setLoginErr(err.message);
  //     if (err.message === "User is not confirmed.") {
  //       //const userdata = await Auth.resendSignUp(email)
  //       console.log(user);
  //       setCognitoUserName(email);
  //       //const us=await Auth.resendSignUp(email)
  //       setConfirmedUser(false);
  //     } else if (err.message === "") {
  //       setRequiredNewPass(true);
  //     }
  //   }

  //   console.log(cognitoUserName, confirmedUser);
  // };

  async function handleSignUp() {
    try {
      const { isSignUpComplete, userId, nextStep } = await signUp({
        username: email,
        password: password,
        options: {
          userAttributes: {
            "custom:cell_phone": phoneNumber,
            "custom:user_name": name,
          },
          // optional
          autoSignIn: true, // or SignInOptions e.g { authFlowType: "USER_SRP_AUTH" }
        },
      });

      console.log(isSignUpComplete, userId, nextStep);
      navigate(`/confirmsignup/${email}`);
    } catch (error) {
      console.log("error signing up:", error.message);
      setLoginErr(error.message);
    }
  }

  const routes = [{}, {}];

  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "internal",
          route: "/signin",
          label: "Sign In",
          color: "info",
        }}
        transparent
        light
      />
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MKBox
        px={1}
        width="100%"
        height="100vh"
        mx="auto"
        position="relative"
        zIndex={2}
      >
        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <MKBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MKTypography
                  variant="h4"
                  fontWeight="medium"
                  color="white"
                  mt={1}
                >
                  Create User
                </MKTypography>
              </MKBox>
              <MKBox pt={4} pb={3} px={3}>
                <MKBox component="form" role="form">
                  <MKBox mb={2}>
                    <MKInput
                      type="email"
                      label="Email"
                      fullWidth
                      value={email}
                      onChange={handleChangeEmail}
                    />
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput
                      type="text"
                      label="Name"
                      fullWidth
                      value={name}
                      onChange={handleChangeName}
                    />
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput
                      type="password"
                      label="Password"
                      fullWidth
                      value={password}
                      onChange={handleChangePassword}
                    />
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput
                      type="password"
                      label="Confirm Password"
                      fullWidth
                      value={confirmPassword}
                      onChange={handleChangeConfirmPassword}
                    />
                  </MKBox>
                  <MKBox mt={4} mb={1}>
                    <MKTypography variant="gradient" color="warning" fullWidth>
                      {loginErr}
                    </MKTypography>
                    <MKTypography variant="h6" color="warning" fullWidth>
                      {checkPassErr}
                    </MKTypography>
                  </MKBox>
                  <MKBox mt={4} mb={1}>
                    <MKButton
                      variant="gradient"
                      color="info"
                      fullWidth
                      onClick={handleSignUp}
                      disabled={!ableSignUp}
                    >
                      Sign up
                    </MKButton>
                  </MKBox>
                </MKBox>
              </MKBox>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
      <MKBox width="100%" position="absolute" zIndex={2} bottom="1.625rem">
        <SimpleFooter light />
      </MKBox>
    </>
  );
}

export default SignUp;
