// react-router components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import MuiLink from "@mui/material/Link";

// Material Kit 2 React components
import MKBox from "../../MKBox";
import MKTypography from "../../MKTypography";
import MKButton from "../../MKButton";
import { CardMedia } from "@mui/material";

function CenteredBlogCard({ image, title, description, action }) {
  return (
    <Card
      sx={{
        height: "400px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <MKBox
        position="relative"
        borderRadius="lg"
        height="130px" // Ajuste en la altura
        mx={2}
        mt={3}
        mb={3}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <CardMedia
          component="img"
          sx={{ width: "70%", height: "auto", objectFit: "contain" }} // Reducción en el tamaño
          image={image}
          alt="imagecard"
        />
      </MKBox>
      <MKBox p={3} mt={-1} textAlign="center" flexGrow={1}>
        <MKTypography
          display="inline"
          variant="h5"
          textTransform="capitalize"
          fontWeight="regular"
        >
          {title}
        </MKTypography>
        <MKBox mt={1} mb={3}>
          <MKTypography variant="body2" component="p" color="text">
            {description}
          </MKTypography>
        </MKBox>
        {action.type === "external" ? (
          <MKButton
            component={MuiLink}
            href={action.route}
            target="_blank"
            rel="noreferrer"
            variant="gradient"
            size="small"
            color={action.color ? action.color : "dark"}
            sx={{
              position: "absolute",
              left: "50%",
              bottom: 40,
              transform: "translateX(-50%)", // Centra horizontalmente
            }}
          >
            {action.label}
          </MKButton>
        ) : (
          <MKButton
            component={Link}
            to={action.route}
            variant="gradient"
            size="small"
            color={action.color ? action.color : "dark"}
            sx={{
              position: "absolute",
              left: "50%",
              bottom: 40,
              transform: "translateX(-50%)", // Centra horizontalmente
            }}
          >
            {action.label}
          </MKButton>
        )}
      </MKBox>
    </Card>
  );
}

// Typechecking props for the CenteredBlogCard
CenteredBlogCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
    label: PropTypes.string.isRequired,
  }).isRequired,
};

export default CenteredBlogCard;
