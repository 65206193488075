import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 React example components
import DefaultNavbar from "../../components/DefaultNavbar";
import SimpleFooter from "../../components/Footer/Simple";
import MKBox from "../../components/MKBox";

// Images
import bgImage from "../../assets/images/bg-sign-in-basic.jpeg";

//Navegate
import { useNavigate } from "react-router-dom";

//AWS COGNITO
import { resetPassword, confirmResetPassword } from "aws-amplify/auth";
import LoginCard from "../../components/Card/LoginCard";

function RecoverPassword() {
  const [email, setEmail] = useState("");
  const [codeEmail, setCodeEmail] = useState("");
  const [newPass, setNewPass] = useState("");
  const [checkNewPass, setCheckNewPass] = useState("");

  const [errEmail, setErrEmail] = useState("");
  const [errCode, setErrCode] = useState("");
  const [errPass, setErrPass] = useState("");

  const [flagShow, setFlagShow] = useState(true);

  const navigate = useNavigate();

  const sendCodeEmail = async () => {
    const output = await resetPassword({
      username: email,
    });

    const { nextStep } = output;
    switch (nextStep.resetPasswordStep) {
      case "CONFIRM_RESET_PASSWORD_WITH_CODE":
        const codeDeliveryDetails = nextStep.codeDeliveryDetails;
        console.log(
          `Confirmation code was sent to ${codeDeliveryDetails.deliveryMedium}`
        );
        // Collect the confirmation code from the user and pass to confirmResetPassword.
        break;
      case "DONE":
        console.log("Successfully reset password.");
        break;
      default:
        console.log("Something happen...");
        setErrEmail("There was a mistake...");
        break;
    }
    setFlagShow(false);
  };

  const setNewPassword = async () => {
    try {
      console.log("Enviando estos datos:", email, codeEmail, newPass);
      await confirmResetPassword({
        username: email,
        confirmationCode: codeEmail,
        newPassword: newPass,
      });
      navigate("/signin");
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheckPasswords = (event) => {
    const value = event.target.value;
    setCheckNewPass(value);
    if (newPass !== value) {
      setErrPass("Passwords do not match!");
    } else {
      setErrPass("");
    }
  };

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleChangeNewPass = (event) => {
    setNewPass(event.target.value);

    if (checkNewPass && checkNewPass !== event.target.value) {
      setErrPass("Passwords do not match!");
    } else {
      setErrPass("");
    }
  };

  const handleChangeCode = (event) => {
    setCodeEmail(event.target.value);
  };

  const routes = [{}, {}];

  const isButtonDisabled =
    errPass !== "" || newPass === "" || checkNewPass === "" || codeEmail === "";

  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "internal",
          route: "/signup",
          label: "Sign Up",
          color: "info",
        }}
        transparent
        light
      />
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MKBox
        px={1}
        width="100%"
        height="100vh"
        mx="auto"
        position="relative"
        zIndex={2}
      >
        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            {flagShow ? (
              <LoginCard
                title="Recover Password"
                fields={[
                  { name: "Email", value: email, action: handleChangeEmail },
                ]}
                button={{ function: sendCodeEmail, text: "Send" }}
                alert={[errEmail]}
              />
            ) : (
              <LoginCard
                title="Recover Password"
                fields={[
                  {
                    name: "Code",
                    value: codeEmail,
                    action: handleChangeCode,
                  },
                  {
                    name: "New Password",
                    value: newPass,
                    action: handleChangeNewPass,
                  },
                  {
                    name: "Confirm Password",
                    value: checkNewPass,
                    action: handleCheckPasswords,
                  },
                ]}
                button={{
                  function: setNewPassword,
                  text: "Reset Password",
                  disabled: isButtonDisabled,
                }}
                alert={[errCode, errPass]}
              />
            )}
          </Grid>
        </Grid>
      </MKBox>
      <MKBox width="100%" position="absolute" zIndex={2} bottom="1.625rem">
        <SimpleFooter light />
      </MKBox>
    </>
  );
}

export default RecoverPassword;
