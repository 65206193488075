
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  styleOverrides: {
    padding: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
};
