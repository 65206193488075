// routes
import * as React from "react";
import { RouterProvider } from "react-router-dom";
//import router from "./MainRoutes_v2";
import rooter from "./MainRoutes";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return <RouterProvider router={rooter} />;
}
