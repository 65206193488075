import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { Link } from "@mui/material";
import MKButton from "../MKButton";
import MKTypography from "../MKTypography";

// sessionState:
// 1: No hay sesión iniciada -> mostrar "Sign In" button
// 2: En formulario de inicio de sesión -> mostrar "Sign Up" button
// 3: Sesión iniciada -> mostrar AccountCircle icon

export default function HeaderLogin({ sessionState = 1, setStateRender }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignIn = () => {
    console.log("Sign In clicked");
    setStateRender("signIn");
  };

  const handleSignUp = () => {
    console.log("Sign Up clicked");
    setStateRender("signUp");
  };

  const handleSignOut = () => {
    console.log("Sign Out clicked");
    setStateRender("signOut");
    handleClose();
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        mt: 2,
        mx: "auto",
        maxWidth: "1200px",
        borderRadius: 4,
      }}
    >
      <AppBar
        position="static"
        sx={{
          backgroundColor: "rgba(40, 40, 40, 0.05)",
          backdropFilter: "blur(10px)",
          borderRadius: 2,
        }}
      >
        <Toolbar>
          {/* Texto "Nuxway Services" alineado a la izquierda */}
          <Box sx={{ flexGrow: 1 }}>
            <Link href="/" sx={{ textDecoration: "none" }}>
              <MKTypography
                variant="button"
                fontWeight="bold"
                color="white"
                translate="no"
              >
                Nuxway Services
              </MKTypography>
            </Link>
          </Box>

          {/* Botones/íconos alineados a la derecha */}
          {sessionState === 1 && (
            <MKButton
              onClick={handleSignIn}
              variant="gradient"
              color="info"
              size="small"
            >
              Sign In
            </MKButton>
          )}

          {sessionState === 2 && (
            <MKButton
              onClick={handleSignUp}
              variant="gradient"
              color="info"
              size="small"
            >
              Sign Up
            </MKButton>
          )}

          {sessionState === 3 && (
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle sx={{ color: "white" }} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                disableScrollLock={true}
              >
                <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
