import { configureStore } from "@reduxjs/toolkit";
import cognitoUserReducer from "./features/cognitoUserSlice";
import sessionUserAttributesReducer from "./features/sessionUserAttributesSlice";
import tokenReducer from "./features/tokenSlice";

export default configureStore({
  reducer: {
    cognitoUser: cognitoUserReducer,
    sessionUserAttributes: sessionUserAttributesReducer,
    token: tokenReducer,
  },
});
