// Amplify-Cognito
import SignInUser from "./SignIn";
import { getCurrentUser } from "aws-amplify/auth";

// Utilities
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

// Components
import MKBox from "../../components/MKBox";
import SimpleFooter from "../../components/Footer/Simple";
import Header from "../../components/Header";

// Images
import bgImage from "../../assets/images/bg-sign-in-basic.jpeg";
import { useSelector } from "react-redux";
import HeaderLogin from "../../components/Header/variant";
import SignUpUser from "./SignUp";
import CodeUser from "./CodeUser";
import RecoverPassword from "./RecoverPassword";
import ResetPassword from "./ResetPassword";

function UserControl() {
  const [stateRender, setStateRender] = useState("signIn"); // corregido el typo
  const [render, setRender] = useState(<SignInUser />);

  const navigate = useNavigate();
  const cognitoUser = useSelector((state) => state.cognitoUser.value);
  const token = useSelector((state) => state.token.value);

  useEffect(() => {
    async function currentAuthenticatedUser() {
      try {
        const { username, userId, signInDetails } = await getCurrentUser();
        console.log(`The username: ${username}`);
        console.log(`The userId: ${userId}`);
        console.log(`The signInDetails: ${signInDetails}`);
        navigate("/UserHome");
      } catch (err) {
        console.log(err);
      }
    }
    currentAuthenticatedUser();
  }, [token]);

  // Cambiar el componente renderizado según el estado
  useEffect(() => {
    switch (stateRender) {
      case "signIn":
        console.log("Changing StateRender to signIn");
        setRender(<SignInUser setStateRender={setStateRender} />);
        break;
      case "signUp":
        console.log("Changing StateRender to signUp");
        setRender(<SignUpUser setStateRender={setStateRender} />);
        break;
      case "ConfirmSignUp":
        console.log("Changing StateRender to ConfirmSignUp");
        setRender(<CodeUser setStateRender={setStateRender} />);
        break;
      case "ForgotPassword":
        console.log("Changing StateRender to ForgotPassword");
        setRender(<RecoverPassword setStateRender={setStateRender} />);
        break;
      case "ResetPassword":
        console.log("Changing StateRender to ResetPassword");
        setRender(<ResetPassword setStateRender={setStateRender} />);
        break;

      case "UserHome":
        console.log("Changing StateRender to ConfirmSignUp");
        navigate("/UserHome");
        break;

      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateRender]);

  return (
    <>
      <MKBox position="fixed" top="0.5rem" width="100%" zIndex={10}>
        <HeaderLogin sessionState={2} setStateRender={setStateRender} />
      </MKBox>
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: (theme) =>
            `${theme.functions.linearGradient(
              theme.functions.rgba(theme.palette.gradients.dark.main, 0.6),
              theme.functions.rgba(theme.palette.gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />

      <MKBox
        px={1}
        width="100%"
        height="100vh"
        mx="auto"
        position="relative"
        zIndex={2}
      >
        {render}
      </MKBox>

      <MKBox width="100%" position="absolute" zIndex={2} bottom="1.625rem">
        <SimpleFooter light />
      </MKBox>
    </>
  );
}

export default UserControl;
