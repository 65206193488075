import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { Button, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";

// sessionState:
// 1: No hay sesión iniciada -> mostrar "Sign In" button
// 2: En formulario de inicio de sesión -> mostrar "Sign Up" button
// 3: Sesión iniciada -> mostrar AccountCircle icon

export default function Header({ sessionState = 1 }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

  const navigate = useNavigate();

  const pages = [
    {
      text: "Docs",
      sections: [
        {
          text: "NuxCaller Manual",
          link: "https://files-nuxservices.s3.us-east-2.amazonaws.com/NuxCaller_Manual_1.0.0.pdf",
        },
        {
          text: "Blog",
          link: "https://www.nuxway.net/blog",
        },
      ],
    },
  ];

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePageMenu = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePageMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleSignIn = () => {
    console.log("Sign In clicked");
    navigate("/UserControl");
  };

  const handleSignUp = () => {
    console.log("Sign Up clicked");
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        mt: 2,
        mx: "auto",
        maxWidth: "1200px",
        borderRadius: 4,
      }}
    >
      <AppBar
        position="static"
        sx={{
          backgroundColor: "rgba(240, 240, 240, 0.95)",
          backdropFilter: "blur(10px)",
          borderRadius: 2,
        }}
      >
        <Toolbar>
          <Link href="/">
            <Typography
              variant="h6"
              color="white"
              sx={{ mr: 2, textDecoration: "none" }}
            >
              Nuxway Services
            </Typography>
          </Link>

          <Box
            sx={{ display: "flex", flexGrow: 1, justifyContent: "flex-end" }}
          >
            {pages.map((page, index) => (
              <div key={index}>
                <Button
                  onClick={handlePageMenu}
                  sx={{
                    borderRadius: "0",
                    color: "white",
                    display: "block",
                    borderRight:
                      index !== pages.length - 1 ? "1.5px solid black" : "none",
                    paddingRight: "16px",
                    paddingLeft: "16px",
                  }}
                >
                  <Typography
                    variant="h7"
                    component="a"
                    color="white"
                    sx={{ textDecoration: "none" }}
                  >
                    {page.text}
                  </Typography>
                </Button>
                <Menu
                  id={`menu-appbar-${index}`}
                  anchorEl={menuAnchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  open={Boolean(menuAnchorEl)}
                  onClose={handlePageMenuClose}
                  disableScrollLock={true}
                >
                  {page.sections?.map((section, idx) => (
                    <MenuItem
                      key={idx}
                      component="a"
                      href={section.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={handlePageMenuClose}
                    >
                      {section.text}
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            ))}
          </Box>

          {/* Condiciones según el estado de la sesión */}
          {sessionState === 1 && (
            <Button
              onClick={handleSignIn}
              sx={{
                color: "#ffffff",
                textTransform: "none",
                backgroundColor: "#e54f14",
                "&:hover": {
                  color: "#007bff", // Cambia el color de la letra a azul al hacer hover
                  boxShadow: "0px 0px 10px 2px rgba(0, 123, 255, 0.8)", // Añade una sombra azul para simular el brillo
                },
              }}
            >
              Sign In
            </Button>
          )}

          {sessionState === 2 && (
            <Button
              onClick={handleSignUp}
              sx={{
                color: "#ffffff",
                textTransform: "none",
                backgroundColor: "#e54f14",
                "&:hover": {
                  color: "#007bff", // Cambia el color de la letra a azul al hacer hover
                  boxShadow: "0px 0px 10px 2px rgba(0, 123, 255, 0.8)", // Añade una sombra azul para simular el brillo
                },
              }}
            >
              Sign Up
            </Button>
          )}

          {sessionState === 3 && (
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle sx={{ color: "white" }} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                disableScrollLock={true}
              >
                <MenuItem onClick={handleClose}>Sign Out</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
